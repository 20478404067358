import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "갈등, 차별 그리고 혐오",
  "slug": "data3",
  "date": "2022-02-23T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb03.jpg",
  "featuredImg": "../images/featured/featured03.jpg",
  "summary": "한국인들은 우리 사회의 혐오와 차별에 대해 어떻게 생각하는지를 조사해보았다.",
  "tags": ["갈등", "차별", "혐오", "한국사회"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`한국 사회에서 '혐오'와 '차별'이 사회 문제로 떠오르고 있다. 이것은 '갈등과는 다르다. 갈등은 주로 집단 간에 일어나는데, 자기 집단 이익에 대한 욕망이 근거가 된다.`}<br parentName="p"></br>{`
`}{`지역 갈등이나 노사 갈등 등은 이해관계 대립이 해소되면 풀릴 수 있다.`}<br parentName="p"></br>{`
`}{`하지만 혐오는 특정 집단을 일방적으로 미워하는 마음과 언행이다. 개인과 집단의 이익과도 큰 상관이 없다. 내게 직접 피해를 주지 않는데도 나와 다르다는 이유로 상대를 미워한다. 그리고 차별한다. 혐오와 차별은 이익 추구가 최우선 가치인 현대 자본주의 사회에서 설명하기 힘든 현상이면서 사회가 병들었다는 신호가 된다.`}<br parentName="p"></br>{`
`}{`한국인들은 우리 사회의 혐오와 차별에 대해 어떻게 생각하는지를 조사해보았다.`}</p>
    <h2>{`가장 심각한 갈등은 무엇인가?`}</h2>
    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`가장 심각한 사회 갈등으로는 빈부 갈등, 이념 갈등, 성 갈등 순으로 답변이 나왔다. 세대별로 차이가 큰데, 젊은 세대는 성(性) 갈등을 가장 심각하다고 보지만, 중년층은 빈부 갈등이 제일 큰 문제라고 생각하고 있다. 반면 장년층은 이념 갈등을 가장 우려하고 있다.
지역 갈등이 가장 심각하다는 의견은 많지 않았다. 60대 이상이 약간 더 높고 다른 연령대에서는 채택 비율이 낮았다. 과거 여론조사에서 지역 갈등을 문제로 꼽은 비율이 높았던 호남 지역에서도 낮은 응답을 보였다.
특이한 점은 20대 여성의 62%가 성 갈등을 가장 심각한 문제라고 답한 것이다. 빈부 갈등과 이념 갈등이라는 응답을 압도적으로 제쳤다.`}</p>
    <h3>{`차별 경험은 나이가 어릴수록 더 많다.`}</h3>
    <p>{`한국 사회에서 차별 경험은 나이가 적을수록 더 많다고 느끼는 것으로 나타났다. 40대와 50대는 절반 정도가 차별을 경험했다고 응답했지만 20대와 30대는 10명 중 6명이 차별 경험이 있다고 응답했다. 젊은 세대가 사회생활을 한 물리적 시간이 짧음에도 차별 경험이 더 많다고 답한 것은 현재 이들이 느끼는 사회경제적 박탈감이 상당하다는 것을 보여준다. 특히 20대 여성은 72.2%가 차별 경험이 있다고 응답해 20대 남성의 같은 답과 큰 차이가 났다. 30대도 여성은 61.8%, 남성은 51.0%로 성별 간 차별 경험이 달랐다.`}</p>
    <h3>{`우리 사회에서 혐오와 차별`}</h3>
    <p>{`우리 사회에서 혐오와 차별이 떠오르는 현상의 배후에는 장기간 계속된 경제적 · 정치적 불평등과 개인 소외 현상이 있다는 데 사회과학자들의 공통된 의견이다.
불평등은 박탈감, 억울함, 불안, 분노를 일으키며 공동체 해체를 불러왔다. 그리고 욕구와 욕망을 충족시킬 곳을 찾기 힘들어진 개인이 나와 다른 집단, 나보다. 힘이 약한 소수 집단을 향해 분노를 표출하는 경향이 일어난 것이다. 공동체를 되살리는 게 혐오와 차별을 없애는 가장 좋은 방법이다. 하지만 주변에 대한 작은 관심과 그 관심을 키우려는 노력에서부터 혐오와 차별을 줄이는 일을 시작해야 할 때다.`}</p>
    <h6>{`*<국민일보>와 공공의창이 기획하고 여론조사기관 리얼미터가 조사를 수행했다. 2018년 12월 2~3일 19세 이상 전국 성인남녀를 대상으로 ARS(유선 20%, 무선 80%) 방식으로 시행했다. 1만 2,644명에게 전화를 걸어 응답률 8.0%를 기록하고 표 본오차는 95% 신뢰수준에서 ±3.1%p다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      